import React from "react";
import './css/FooterComponent.css';
import { Navbar, Nav, Container } from "react-bootstrap";

import logo from './images/logo.png';

const FooterComponent = () => {
  return (
    <div class="d-flex flex-column h-100">
      <footer class="w-100 py-4 flex-shrink-0">
        <div class="container py-4">
          <div class="row gy-4 gx-5">
            <div class="col-lg-4 col-md-6">
              <h5 class="h1 text-white">The LilyPod</h5>
              <p class="small text-white">The Lily Pod is an ideal, self catering, romantic retreat for weekends or holidays. The Lily Pod is for your exclusive use when you book, you will be the only guests onsite.</p>
              <p class="small text-white mb-0">&copy; Copyright 2024. All rights reserved.</p>
            </div>
            <div class="col-lg-2 col-md-6">
              <h5 class="text-white mb-3">Quick links</h5>
              <ul class="list-unstyled text-muted">
                <li><a href="/">Home</a></li>
                <li><a href="/amenities">Amenities</a></li>
                <li><a href="/gallery">Gallery</a></li>
                <li><a href="/links">Links</a></li>
                <li><a href="/contact">Contact</a></li>
              </ul>
            </div>
            <div class="col-lg-2 col-md-6">
              
            </div>
            <div class="col-lg-4 col-md-6">
              <h5 class="text-white mb-3">Social Media</h5>
              <ul class="socials d-flex align-items-center">
                <li class="list-item">
                  <a class="link" href="https://www.facebook.com/thelilypodscotland/">
                    <i class="icon-facebook"></i>
                  </a>
                </li>
                <li class="list-item">
                  <a class="link" href="https://www.instagram.com/lilypod2020/">
                    <i class="icon-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
};

export default FooterComponent;