import './css/AmenitiesComponent.css'

import kitchen from './images/kitchen.png'
import puddock from './images/puddockhole.jpg'
import shower from './images/shower.jpg'
import hottub from './images/hot tub.jpg'
import outdoor from './images/outdoor.jpg'
import dogpod from './images/dog pod.png'


const AmenitiesComponent = () => {
    return (
        <div class="amenitiesContainer">
            <header class="page">
                <div class="container">
                    <ul class="breadcrumbs d-flex flex-wrap align-content-center">
                        <li class="list-item">
                            <a class="link" href="/">Home</a>
                        </li>

                        <li class="list-item">
                            <a class="link" href="/amenities">Amenities</a>
                        </li>
                    </ul>
                    <h1 class="page_title">Amenities</h1>
                </div>
            </header>
            <section class="amenitiesList">

                <div class="container">
                    <div class="card mb-3" data-order="1" data-aos="fade-up">
                        <div class="row g-0">
                            <div class="col-md-4">
                                <img src={kitchen} class="img-fluid rounded-start amenityImg" alt="..." />
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    <h5 class="card-title">Kitchen</h5>
                                    <p class="card-text">
                                        We have a small kitchen with crockery, cutlery and cooking utensils.
                                        The kitchen also has a dishwasher ice maker, fridge / freezer, cooker and air fryer.
                                        A selection of condiments are supplied for your use.
                                    </p>
                                    <p class="card-text">
                                        <div>
                                            <a
                                                class="greenbutton"
                                                href="https://www.airbnb.co.uk/rooms/27287141"
                                                target="_blank"
                                            >Book now</a
                                            >
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mb-3" data-order="2" data-aos="fade-up">
                        <div class="row g-0">
                            <div class="col-md-4">
                                <img src={puddock} class="img-fluid rounded-start amenityImg" alt="..." />
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    <h5 class="card-title">The Puddock Hole</h5>
                                    <p class="card-text"> A small cabin that compliments the Lily Pod with a smart tv,
                                        wood burning stove, extensive reading material, selection of
                                        games and a magical atmosphere.</p>
                                    <p class="card-text">
                                        <div>
                                            <a
                                                class="greenbutton"
                                                href="https://www.airbnb.co.uk/rooms/27287141"
                                                target="_blank"
                                            >Book now</a
                                            >
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="card mb-3" data-order="3" data-aos="fade-up">
                        <div class="row g-0">
                            <div class="col-md-4">
                                <img src={shower} class="img-fluid rounded-start amenityImg" alt="..." />
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    <h5 class="card-title">Private Bathroom and Shower</h5>
                                    <p class="card-text"> We have a small bow top pod right next door to the Lily Pod
                                        that includes a heated shower, flushing toiled and sink for
                                        your exclusive use.</p>
                                    <p class="card-text">
                                        <div>
                                            <a
                                                class="greenbutton"
                                                href="https://www.airbnb.co.uk/rooms/27287141"
                                                target="_blank"
                                            >Book now</a
                                            >
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mb-3" data-order="4" data-aos="fade-up">
                        <div class="row g-0">
                            <div class="col-md-4">
                                <img src={hottub} class="img-fluid rounded-start amenityImg" alt="..." />
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    <h5 class="card-title">Hot Tub</h5>
                                    <p class="card-text">  Wood fired Hot Tub, unlimited, exclusive, all year round
                                        access for your booking. We supply the first heat of the tub
                                        inclusive of your booking fee. There after, we can supply
                                        wood for a small fee for your use.</p>
                                    <p class="card-text">
                                        <div>
                                            <a
                                                class="greenbutton"
                                                href="https://www.airbnb.co.uk/rooms/27287141"
                                                target="_blank"
                                            >Book now</a
                                            >
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mb-3" data-order="5" data-aos="fade-up">
                        <div class="row g-0">
                            <div class="col-md-4">
                                <img src={outdoor} class="img-fluid rounded-start amenityImg" alt="..." />
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    <h5 class="card-title">Outdoor Area</h5>
                                    <p class="card-text">  Our out door area boasts a pizza over, fire pits, BBQ and
                                        seating area. Again, this is entirely for your exclusive
                                        use.</p>
                                    <p class="card-text">
                                        <div>
                                            <a
                                                class="greenbutton"
                                                href="https://www.airbnb.co.uk/rooms/27287141"
                                                target="_blank"
                                            >Book now</a
                                            >
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mb-3" data-order="6" data-aos="fade-up">
                        <div class="row g-0">
                            <div class="col-md-4">
                                <img src={dogpod} class="img-fluid rounded-start amenityImg" alt="..." />
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    <h5 class="card-title">Dog Pod</h5>
                                    <p class="card-text">  A mini pod for your fur baby. Although we don't expect your
                                        doggy to stay in the dog pod they might like to lounge in
                                        there while you are using the hot tub.</p>
                                    <p class="card-text">
                                        <div>
                                            <a
                                                class="greenbutton"
                                                href="https://www.airbnb.co.uk/rooms/27287141"
                                                target="_blank"
                                            >Book now</a
                                            >
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    )
};

export default AmenitiesComponent;

