
import './App.css';
import HeaderComponent from './HeaderComponent';
import 'bootstrap/dist/css/bootstrap.css';
import FooterComponent from './FooterComponent';
import HomeCompontnet from './HomeComponent';
import { Routes, Route, Link, BrowserRouter } from "react-router-dom";
import AmenitiesComponent from './AmenitiesComponent';
import GalleryComponent from './GalleryComponent';
import LinksComponent from './LinksComponent';
import ContactComponent from './ContactComponent';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <HeaderComponent></HeaderComponent>
        <Routes>
          <Route path="/" element={<HomeCompontnet />} />
          <Route path="/amenities" element={<AmenitiesComponent />} />
          <Route path="/gallery" element={<GalleryComponent />} />
          <Route path="/links" element={<LinksComponent />} />
          <Route path="/contact" element={<ContactComponent />} />
        </Routes>
        <FooterComponent></FooterComponent>
      </BrowserRouter>
    </div>
  );
}

export default App;
