import {React, useEffect} from "react";
import './css/HeaderComponent.css';
import './css/libs.css'
import { Navbar, Nav, Container } from "react-bootstrap";
import AOS from 'aos';
import 'aos/dist/aos.css';

import logo from './images/logo.png';

const HeaderComponent = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <Navbar expand="md">
        <Container>
          <Navbar.Brand href="/">
              <img src={logo} alt="The LilyPod glamping" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto" activeKey={window.location.pathname} >
              <Nav.Link href="/" className={"navbar-link"}>
                Home
              </Nav.Link>
              <Nav.Link href="/amenities" className={"navbar-link"}>
                Amenities
              </Nav.Link>
              <Nav.Link href="/gallery" className={"navbar-link"}>
                Gallery
              </Nav.Link>
              <Nav.Link href="/links" className={"navbar-link"}>
                Links
              </Nav.Link>
              <Nav.Link href="/contact" className={"navbar-link"}>
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default HeaderComponent;

