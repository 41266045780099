import './css/ContactComponent.css'
import { useForm } from "react-hook-form";
import $ from 'jquery';
import ReCAPTCHA from 'react-google-recaptcha';
import React, { useReducer, useRef } from 'react';

import contact from './images/contact.png'

const ContactComponent = () => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const captchaRef = useRef(null)

    const onSubmit = data => {

        const token = captchaRef.current.getValue();

        if (token != "" && token!= undefined)
        {
            var URL = "https://o2ymigtpr5kyobt63b3ylbyaty0pvdql.lambda-url.eu-west-2.on.aws/";

            var data = {
                name : data.feedbackName,
                phone : '0',
                email : data.feedbackEmail,
                desc : data.feedbackMessage,
                rcres: token
              };
              
             $.ajax({
               type: "POST",
               url : URL,
               dataType: "json",
               crossDomain: "true",
               contentType: "application/json; charset=utf-8",
               data: JSON.stringify(data),
               success: function () {
                 document.location.reload();
               },
               error: function (jqXHR, exception) {
                 // show an error message
                 alert("UnSuccessfull: " + jqXHR.status);
               }});
        }

        

        captchaRef.current.reset();
        reset();
    }

    return (

        <div className="contactContainer">

            <header class="page">
                <div class="container">
                    <ul class="breadcrumbs d-flex flex-wrap align-content-center">
                        <li class="list-item">
                            <a class="link" href="/">Home</a>
                        </li>

                        <li class="list-item">
                            <a class="link" href="/contact">Contact</a>
                        </li>
                    </ul>
                    <h1 class="page_title">Contact</h1>
                </div>
            </header>
            <section class="contactsp_main section">
                <div class="container container--contactsp d-xl-flex align-items-center">
                    <div class="contactsp_info col-xl-7" data-aos="fade-up">
                        <div class="contactsp_info-header">
                            <h2 class="contactsp_info-header_title">Contact Info</h2>
                            <p class="contactsp_info-header_text">
                                Our dream of a Romany style glamping pod has finally come true
                                in the heart of Buchan, near the North Sea coast.
                            </p>
                        </div>
                        <div class="contactsp_info-main">
                            <div class="contactsp_info-main_block d-sm-flex align-items-start">
                                <span class="icon-call icon">
                                    <svg
                                        width="28"
                                        height="29"
                                        viewBox="0 0 28 29"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M26.9609 19.75L21 17.1797C20.7812 17.125 20.5625 17.0703 20.3438 17.0703C19.7969 17.0703 19.3047 17.2891 19.0312 17.6719L16.625 20.625C12.7969 18.7656 9.73438 15.7031 7.875 11.875L10.8281 9.46875C11.2109 9.19531 11.4297 8.70312 11.4297 8.15625C11.4297 7.9375 11.375 7.71875 11.3203 7.5L8.75 1.53906C8.47656 0.9375 7.875 0.5 7.21875 0.5C7.05469 0.5 6.94531 0.554688 6.83594 0.554688L1.3125 1.86719C0.546875 2.03125 0 2.6875 0 3.50781C0 17.3438 11.2109 28.5 24.9922 28.5C25.8125 28.5 26.4688 27.9531 26.6875 27.1875L27.9453 21.6641C27.9453 21.5547 27.9453 21.4453 27.9453 21.2812C27.9453 20.625 27.5625 20.0234 26.9609 19.75ZM24.9375 26.75C12.1406 26.75 1.75 16.3594 1.75 3.5625L7.16406 2.30469L9.67969 8.15625L5.6875 11.4375C8.36719 17.0703 11.4297 20.1328 17.1172 22.8125L20.3438 18.8203L26.1953 21.3359L24.9375 26.75Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                </span>
                                <div class="wrapper d-flex flex-column">
                                    <a class="link" href="tel:+44 (0)7949 971628"
                                    >+44 (0)7949 971628</a
                                    >
                                </div>
                            </div>
                            <div class="contactsp_info-main_block d-sm-flex align-items-start">
                                <i class="icon-email icon"></i>
                                <div class="wrapper d-flex flex-column">
                                    <a class="link" href="mailto:info@thelilypod.co.uk"
                                    >info@thelilypod.co.uk</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="contactsp_map">
                        <div>
                            <div>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d17174.623754403397!2d-1.872909!3d57.447198!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4883880af5bb7223%3A0x9888f719bcc069e9!2sThe%20Steadings%20Eastern%20Teuchan%2C%20Cruden%20Bay%2C%20Peterhead%20AB42%200PP!5e0!3m2!1sen!2suk!4v1656400450176!5m2!1sen!2suk"
                                    width="100%"
                                    height="600"
                                    style={{ border: 0 }}
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="contactsp_secondary section">
                <div
                    class="container d-xl-flex align-items-center justify-content-between"
                >
                    <div class="contactsp_secondary-rating">
                        <img src={contact} alt="" />
                    </div>
                    <div class="contactsp_secondary-form" data-aos="fade-up">
                        <div class="contactsp" data-aos="fade-up">
                            <div class="contactsp_header">
                                <h2 class="contactsp_header-title">Get in Touch</h2>
                                <p class="contactsp_header-text">
                                    Our dream of a Romany style glamping pod has finally come true
                                    in the heart of Buchan, near the North Sea coast.
                                </p>
                            </div>
                            <form
                                class="contactsp_form form d-sm-flex flex-wrap justify-content-between"
                                action="#"
                                method="post"
                                data-type="feedback"
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <div class="field-wrapper">
                                    <label class="label" for="feedbackName">
                                        <i class="icon-user icon"></i>
                                    </label>
                                    <input
                                        class="field required"
                                        id="feedbackName"
                                        type="text"
                                        placeholder="Name"
                                        {...register("feedbackName", { required: true })}
                                    />
                                    {errors.feedbackName && <span class="error">This field is required</span>}
                                </div>
                                <div class="field-wrapper">
                                    <label class="label" for="feedbackEmail">
                                        <i class="icon-email icon"></i>
                                    </label>
                                    <input
                                        class="field required"
                                        id="feedbackEmail"
                                        type="text"
                                        data-type="email"
                                        placeholder="Email"
                                        {...register("feedbackEmail", {
                                            pattern: /^\S+@\S+\.\S+$/,
                                            required: true
                                        })}
                                    />
                                    {errors.feedbackEmail && <span class="error">Email is invalid</span>}
                                </div>

                                <textarea
                                    class="field textarea required"
                                    id="feedbackMessage"
                                    placeholder="Message"
                                    {...register("feedbackMessage")}
                                ></textarea>
                                <div style={{ marginTop: 5 }}>
                                    <ReCAPTCHA name="recaptcha" id="recaptcha" sitekey="6LcWeocgAAAAAEGskedhjdM5M3dHnOJBSxkcfaTn" ref={captchaRef}></ReCAPTCHA>
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    <button
                                        class="greenbutton"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default ContactComponent;