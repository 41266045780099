import * as React from "react";
import './css/GalleryComponent.css'
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

// import images
const images = require.context('./images/gallery', true);
const imageList = images.keys().map(image => images(image));


const photos = imageList.map(photo => ({
    src: photo, width: 400, height: 400
}));

const lightImages = imageList.map(photo => ({
    src: photo
}));

const GalleryComponent = () => {
    const [index, setIndex] = React.useState(-1);
    return (
        <div class="galleryContainer">
            <header class="page">
                <div class="container">
                    <ul class="breadcrumbs d-flex flex-wrap align-content-center">
                        <li class="list-item">
                            <a class="link" href="/">Home</a>
                        </li>

                        <li class="list-item">
                            <a class="link" href="/gallery">Gallery</a>
                        </li>
                    </ul>
                    <h1 class="page_title">Gallery</h1>
                </div>
            </header>
            <section class="galleryImages">

                <PhotoAlbum
                    layout="rows"
                    photos={photos}
                    onClick={({ index: current }) => setIndex(current)}
                />
                <Lightbox
                    index={index}
                    slides={lightImages}
                    open={index >= 0}
                    close={() => setIndex(-1)}
                    plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                />
            </section>
        </div>
    );
};

export default GalleryComponent;