import './css/LinksComponent.css'

const LinksComponent = () => {
    return (
        <div class="linksContainer">
            <header class="page">
                <div class="container">
                    <ul class="breadcrumbs d-flex flex-wrap align-content-center">
                        <li class="list-item">
                            <a class="link" href="/">Home</a>
                        </li>

                        <li class="list-item">
                            <a class="link" href="/links">Links</a>
                        </li>
                    </ul>
                    <h1 class="page_title">Links</h1>
                </div>
            </header>
            <section>
                <div class="LinksList container">
                    <div class="ct-row">
                        <div class="ct-sectional-box ct-sectional-box--green">
                            <h2 class="title">&nbsp;&nbsp;Tourism</h2>
                            <div class="inner">
                                <ul>
                                    <li><a href="https://www.visitscotland.com/" target='_blank'>Visit Scotland</a></li>
                                    <li><a href="https://www.scotland.org/visit" target='_blank'>Scotland.org</a></li>
                                    <li><a href="https://www.nts.org.uk/" target='_blank'>National Trust of Scotland</a></li>
                                    <li><a href="https://www.airbnb.co.uk/rooms/27287141?source_impression_id=p3_1655055557_kh1fRNpf66YKOHvr" target='_blank'>AirBnB</a></li>
                                    <li><a href="https://www.tripadvisor.co.uk/" target='_blank'>Trip Advisor</a></li>

                                </ul>
                            </div>
                        </div>
                        <div class="ct-sectional-box ct-sectional-box--green">
                            <h2 class="title">&nbsp;&nbsp;Things To Do</h2>
                            <div class="inner">
                                <ul>
                                    <li><a href="https://crudenbaygolfclub.co.uk/" target='_blank'>Cruden Bay Golf Club</a></li>
                                    <li><a href="https://peterheadprisonmuseum.com/" target='_blank'>Peterhead Prison Museum</a></li>
                                    <li><a href="https://www.aberdeenshire.gov.uk/leisure-sport-and-culture/country-parks/mintlaw-aden-country-park/" target='_blank'>Aden Country Park</a></li>
                                    <li><a href="https://www.nts.org.uk/visit/places/haddo-house" target='_blank'>Haddo House</a></li>
                                    <li><a href="https://www.livelifeaberdeenshire.org.uk/sport-and-physical-activity/venues/peterhead-leisure-and-community-centre/" target='_blank'>Peterhead Leisure and Community centre </a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="ct-sectional-box ct-sectional-box--green">
                            <h2 class="title">&nbsp;&nbsp;Information</h2>
                            <div class="inner">
                                <ul>
                                    <li><a href="https://en.wikipedia.org/wiki/Cruden_Bay" target='_blank'>Cruden Bay Wiki</a></li>
                                    <li><a href="https://en.wikipedia.org/wiki/New_Slains_Castle" target='_blank'>Slains Castle Wiki</a></li>
                                    <li><a href="https://www.thebeachguide.co.uk/north-scotland/grampian/top-10-beaches" target='_blank'>Beach Guide - Grampian</a></li>
                                    <li><a href="https://en.wikipedia.org/wiki/Bullers_of_Buchan" target='_blank'>Bullers of Buchan</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div >
    )
};

export default LinksComponent;